import React from 'react';
import Hero from '../Hero';
import Section from '../Section';
import SmallLink from '../SmallLink';

export const B4TKContent = ({ hero, blurb, item, videoInfo }) => {
  return (
    <>
      <Hero images={hero.images} title={hero.text} />
      <Section>
        <p className="text-3xl font-baloo text-black text-left sm:text-justify mb-16">
          {blurb}
        </p>
        <p className="font-baloo font-bold text-s4tk-blue text-4xl sm:text-5xl lg:self-start text-left sm:text-justify mb-4">
          {videoInfo.caption}
        </p>
        <div className="relative w-full md:w-3/4 h-0 pb-16/9 md:pb-16/more mb-16">
          <div className="absolute w-full h-full z-0 bg-black opacity-10 top-2 left-2 lg:top-8 lg:left-8"></div>
          <iframe
            src={videoInfo.url}
            title="video"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
            className="w-full h-full absolute top-0 left-0 z-10"
          ></iframe>
        </div>
      </Section>
      <div className="w-full px-4 flex items-center justify-center pb-16">
        <SmallLink item={item} />
      </div>
    </>
  );
};

export default B4TKContent;
